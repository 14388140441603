import React from 'react';
import styled from 'styled-components';
import { FilterGameComponent } from './filter-game';

const Wrapper = styled.div`
  position:  fixed;
  width: 100%;
  height: 100vh;
  z-index: 30;
  background: rgba(255, 255, 255, 0.34);
  border-radius: 16px;
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(9.9px);
  -webkit-backdrop-filter: blur(9.9px);

  @media screen and (min-width: 769px) {
    display: none;
  }
`

const Content = styled.div`
  position: absolute;
  right: 0;
  width: 349px;
  height: 100%;
  background: var(--bg-primary);
  padding: 20px;

  .box-filter {
    height: 80vh;
    overflow: scroll;
  }
`

const ButtonClose = styled.div`
  position: fixed;
  right: 20px;

  .btn-close {
    width: 30px;
    height: 30px;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .line-1 {
    border: 1px solid #8100BE;
    rotate: 45deg;
    width: 20px;
    position: absolute;
  }

  .line-2 {
    border: 1px solid #8100BE;
    rotate: -45deg;
    width: 20px;
    position: absolute;
  }
`

const Button = styled.div`
  width: 100%;
  height: 48px;
  background: transparent linear-gradient(259deg, #F8B34C 0%, #A605F2 100%) 0% 0% no-repeat padding-box;
  border-radius: 8px;
  opacity: 1;
  margin-top: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
`

export default function FilterGameMobile({ varFilter, toggleFilterMobile }) {
  return (
    <Wrapper>
      <Content>
        <ButtonClose>
          <button onClick={toggleFilterMobile}>
            <div className='btn-close'>
              <div className='line-1'></div>
              <div className='line-2'></div>
            </div>
          </button>
        </ButtonClose>
        <div className='box-filter'>
          <FilterGameComponent varFilter={varFilter} />
        </div>
        <Button onClick={toggleFilterMobile} role='button'>Confirm</Button>
      </Content>
    </Wrapper>
  )
}